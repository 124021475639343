const _name = Symbol('name'),
      _namespace = Symbol('namespace'),
      _attributes = Symbol('attributes'),
      _cookie = Symbol('cookie'),
      _destroyed = Symbol('destroyed');

class CookieManager {
  constructor(name, attributes = {}, cookiesApi = null) {
		attributes.byClient = attributes.byClient === undefined ? true : attributes.byClient;

		this.name = attributes.byClient && Ingtech.clientId ? `${name}/${Ingtech.clientId}` : name;
    this._namespace = cookiesApi || Cookies;
    this._attributes = attributes;
    this._cookie = {};
    this._data = {};
    
    if (name != 'cookies-expirations') {
      CookieManager.CookiesExpiration.set(this.name, attributes.expires ? attributes.expires : 'session');
    }

    this.load();
  }

  set(prop, value, autoApply = true) {
    if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie.');
    if (prop == 'this') throw new Error('You cannot set "this".');

    if (value === null) return this.remove(prop);

    this._data[prop] = value;
    this._cookie[prop] = convert(value);

    if (autoApply) {
      this.apply();
    }

    return this;
  }

  get(prop, autoLoad = true) {
    if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');

    if (autoLoad) {
      this.load();
    }

    return this._data[prop];
  }

  remove(prop) {
    if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');

    delete this._cookie[prop];
    delete this._data[prop];
    this.apply();

    return this;
  }

  destroy() {
    if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');

    this._namespace.remove(this.name, this._attributes);
    this[_destroyed] = true;
  }

  apply() {
    if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');

    this._namespace.set(this.name, this._cookie, this._attributes);

    return this;
  }

  load() {
    if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');
    
    this._cookie = JSON.parse(this._namespace.get(this.name) || '{}');
    this._data = this.restoreData();

    return this;
  }

  isEmpty() {
    return Object.keys(this._cookie).length == 0;
  }

  setCookie(cookie) {
    for(const [prop, value] of Object.entries(cookie)) {
      this.set(prop, value);
    }
  }

  cookie(autoLoad = false) {
    this.load();

    return new Proxy(this._cookie, {
      set: (obj, prop, value) => {
        if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');

        this.set(prop, value);
        return true;
      },
      get: (obj, prop) => {
        if (this[_destroyed]) throw new Error('You cannot use a destroyed cookie');

        if (prop == "this") {
          return this;
        }
        
        return this.get(prop, autoLoad);
      }
    });
  }

  restoreData() {
    let data = {};

    for (let [prop, value] of Object.entries(this._cookie)) {
      data[prop] = restore(value);
    }

    return data;
  }

  static clearSession() {
    let cookies = CookieManager.CookiesExpiration.cookie();

    for (let [name, expires] of Object.entries(cookies)) {
      if (expires == 'session') {
        Cookies.remove(name);
        cookies[name] = null;
      }
    }
  }
}

function convert(value) {
  for (let customType of CookieManager.CustomType) {
    if (customType.validate(value)) {
      return {
        _isCustomType: true,
        type: customType.type,
        value: customType.set(value)
      };
    }
  }

  return value;
}

function restore(value) {
  if (value && typeof value == 'object' && value._isCustomType) {
    let customType = CookieManager.CustomType.find(ct => ct.type == value.type);

    return customType ? customType.get(value) : value;
  }

  return value;
}

CookieManager.CookiesExpiration = new CookieManager('cookies-expirations', { byClient: false });


CookieManager.CustomType = [
  {
    type: 'moment',
    validate: value => value && typeof value == 'object' && value._isAMomentObject,
    get: value => moment(value.value),
    set: value => value.format()
  },
  {
    type: 'object',
    validate: value => value && typeof value == 'object' && !(value instanceof Array),
    get: value => value.value,
    set: value => Object.getOwnPropertyNames(value).reduce((obj, key) => (obj[key] = value[key], obj), {})
  }
];

Ingtech.CookieManager = CookieManager;